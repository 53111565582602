/* eslint-disable perfectionist/sort-imports */
import "@solvari/common-fe/polyfills";
import "@/platform/utils/sentry";
import "@/vue/utils/solvariConfig";

import { initArgus } from "@solvari/common-fe/argus/service";
import { initAbTesting } from "@solvari/common-fe/ab-testing";
import { initHeyflowForm } from "@/shared/components/heyflow-form/heyflowForm";

import "@/shared/components/trustpilot-rating/trustpilotRating.css";
import "@/platform/layouts/default/default.css";
import "@/platform/components/header/header";
import "@/platform/components/footer/footer";

import { initPlatformVueIslands } from "@/platform/vueIslands";
import { initFontAwesome } from "@solvari/common-fe/integrations";
/* eslint-enable perfectionist/sort-imports */

function main() {
  void initArgus();
  void initAbTesting();
  initFontAwesome();
  initPlatformVueIslands();
  void initHeyflowForm();
}

main();
